<template>
  <base-section
    id="pricing-plan"
    class="text-center"
  >
    <base-section-heading :title="$t('pricingSection.titleLine1')">
      {{ $t('pricingSection.titleLine2') }}
    </base-section-heading>

    <v-btn-toggle
      v-model="interval"
      background-color="white"
      tile
    >
      <v-btn
        active-class="primary white--text"
        width="125"
      >
        {{ $t('pricingSection.monthly') }}
      </v-btn>

      <v-btn
        active-class="primary white--text"
        width="125"
      >
        {{ $t('pricingSection.yearly') }}
      </v-btn>
    </v-btn-toggle>

    <div class="pa-8" />

    <v-container>
      <v-row align="center">
        <v-col
          v-for="(plan, i) in plans"
          :key="i"
          cols="12"
          md="6"
        >
          <price-card v-bind="plan" />
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-card
        color="grey lighten-5"
        :elevation="12"
        class="pa-2"
        outlined
        tile
      >
        <v-card
          flat
          outlined
          tile
        >
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Kidiwi services {{ $t('pricingSection.payPerUse') }}
                  </th>
                  <th class="text-left">
                    {{ $t('pricingSection.description') }}
                  </th>
                  <th class="text-left">
                    {{ $t('pricingSection.price') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in KidiwiServices"
                  :key="item.name"
                >
                  <td
                    class="text-left"
                  >
                    {{ $t(item.name) }}
                  </td>
                  <td
                    class="text-left"
                  >
                    {{ $t(item.description) }}
                  </td>
                  <td
                    class="text-left"
                  >
                    {{ item.price }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-card>
    </v-container>
  </base-section>
</template>

<script>
  import { COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH, COST_BASIC_USAGE_MONTHLY_FEE_PER_YEAR, COST_BASIC_USAGE_LOGO_MONTHLY, COST_BASIC_USAGE_RELATION_UNIT, COST_BASIC_USAGE_SERVICE_UNIT_ABOVE_THRESHOLD } from '@/services/Cost'

  export default {
    name: 'SectionPricingPlan',

    components: {
      PriceCard: () => import('@/components/kdw/PriceCard'),
    },

    provide: {
      heading: { align: 'center' },
    },

    data: () => ({
      features: [
        // 'Full statistics',
        // 'Max 100 items/month',
        // 'Up to 5 users',
        // 'Unlimited Queries',
      ],
      KidiwiServices: [
        {
          name: 'pricingSection.payPerUse_Basic_Relation',
          description: 'pricingSection.payPerUse_Basic_Relation_description',
          price: COST_BASIC_USAGE_RELATION_UNIT,
        },
        {
          name: 'pricingSection.payPerUse_Basic_Logo',
          description: 'pricingSection.payPerUse_Basic_Logo_description',
          price: COST_BASIC_USAGE_LOGO_MONTHLY,
        },
        {
          name: 'pricingSection.payPerUse_Basic_Service',
          description: 'pricingSection.payPerUse_Basic_Service_description',
          price: COST_BASIC_USAGE_SERVICE_UNIT_ABOVE_THRESHOLD,
        },
        {
          name: 'pricingSection.payPerUse_Basic_Spending',
          description: 'pricingSection.payPerUse_Basic_Spending_description',
          price: 0,
        },
      ],
      internalPlans: [
        {
          title: 'pricingSection.basic_Title_monthly',
          subtitle: 'pricingSection.basic_SubTitle_monthly',
          monthly: COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH,
          yearly: COST_BASIC_USAGE_MONTHLY_FEE_PER_MONTH * 12,
          value: false,
        },

        {
          title: 'pricingSection.basic_Title_yearly',
          subtitle: 'pricingSection.basic_SubTitle_yearly',
          monthly: COST_BASIC_USAGE_MONTHLY_FEE_PER_YEAR,
          yearly: COST_BASIC_USAGE_MONTHLY_FEE_PER_YEAR * 12,
          value: true,
        },
        /*
        {
          title: 'Premium',
          subtitle: 'Best Plan for Power Users',
          monthly: 79,
          yearly: 632,
        },
        */
      ],
      interval: 0,
    }),

    computed: {
      plans () {
        return this.internalPlans.map(plan => {
          return {
            ...plan,
            features: this.features,
            interval: !this.interval
              ? 'pricingSection.month'
              : 'pricingSection.year',
            title: plan.title,
            subtitle: plan.subtitle,
            price: !this.interval
              ? plan.monthly
              : plan.yearly,
          }
        })
      },
    },
  }
</script>
